import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

import { FullCalendarModule } from '@fullcalendar/angular';
import { EditorModule } from '@tinymce/tinymce-angular';
// import { AvatarModule } from 'ngx-avatar';

import { CircularProgressComponent } from './circular-progress/circular-progress.component';
import { ProfilePictureComponent } from './profile-picture/profile-picture.component';
import { PreviewImagesComponent } from './preview-images/preview-images.component';
import { SelectImageComponent } from './select-image/select-image.component';
import { BrandSlidesComponent } from './brand-slides/brand-slides.component';
import { SearchUsersComponent } from './search-users/search-users.component';
import { TabataTimerComponent } from './tabata-timer/tabata-timer.component';
import { AmrapTimerComponent } from './amrap-timer/amrap-timer.component';
import { NewsSlidesComponent } from './news-slides/news-slides.component';
import { DateRangeComponent } from './date-range/date-range.component';
import { EmomTimerComponent } from './emom-timer/emom-timer.component';
import { CardUserComponent } from './card-user/card-user.component';
import { CalendarComponent } from './calendar/calendar.component';
import { PopinfoComponent } from './popinfo/popinfo.component';
import { UnpaidComponent } from './unpaid/unpaid.component';
import { HeaderComponent } from './header/header.component';
import { EditorComponent } from './editor/editor.component';

import { PipesModule } from '../pipes/pipes.module';

import localeEs from '@angular/common/locales/es';

registerLocaleData(localeEs);

const declarationsAndExports = [
  HeaderComponent,
  EditorComponent,
  UnpaidComponent,
  PopinfoComponent,
  CardUserComponent,
  CalendarComponent,
  DateRangeComponent,
  EmomTimerComponent,
  NewsSlidesComponent,
  AmrapTimerComponent,
  SelectImageComponent,
  TabataTimerComponent,
  SearchUsersComponent,
  BrandSlidesComponent,
  PreviewImagesComponent,
  ProfilePictureComponent,
  CircularProgressComponent,
];

@NgModule({
  declarations: [
    ...declarationsAndExports
  ],
  exports: [
    ...declarationsAndExports
  ],
  imports: [
    // AvatarModule,
    CommonModule,
    IonicModule,
    EditorModule,
    FormsModule,
    PipesModule,
    // NgCalendarModule,
    FullCalendarModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule { }
